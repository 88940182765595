import * as Sentry from '@sentry/react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import React, { createContext, useMemo, type ReactNode } from 'react';

import { API, DOMAIN, GTM, LANG } from '../Config';
import { transformers } from '../Http';
import { applyConsents } from '../Http/mutation-fns';
import { AuthHttp } from '../Http/urls/Auth.http';
import {
  decodeAccessToken,
  removeTokens,
  useAuthTokens,
  type Tokens,
} from '../tokens';

import { addLegacyGTMEvent, getGaClientId } from '@/lib/analytics/gtm';
import { getRegisterSourceData } from '@/lib/analytics/registerSource';
import { apiClient } from '@/lib/api/apiClient';
import { authInitQuery } from '@/lib/api/queries';

export interface AuthContextValue {
  login: (
    method: 'bankid' | 'vipps',
    returnTo?: string | null,
  ) => Promise<void>;
  logout: () => Promise<void>;
  /** @deprecated use `applyConsents` from `methods.ts` */
  acceptConsents: (consents: string[]) => Promise<void>;
  /** @deprecated */
  deleteUser: () => Promise<void>;
  /** @deprecated */
  getVertex: (clientKnowledge: unknown) => unknown;
  token: Tokens | undefined;
  /** @deprecated use `isAuthInit` directly */
  authData: AuthDataState;
  userId: string | undefined;
  brainSessionId: { id: string } | undefined;
  isAnonymous: boolean;
  isAuthInit: boolean;
}

type AuthDataState = {
  isAuthInit: boolean;
};

export const AuthContext = createContext<AuthContextValue>(undefined!);

export const AuthProvider: React.FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const queryClient = useQueryClient();
  const tokens = useAuthTokens();

  const { data: initData } = useQuery(authInitQuery());

  const isAuthInit = Boolean(initData);
  const userId = initData?.userId;

  const decodedToken = useMemo(
    () =>
      tokens?.accessToken ? decodeAccessToken(tokens.accessToken) : undefined,
    [tokens?.accessToken],
  );

  // This is an object for historical reasons, it should be refactored later
  const brainSessionId = useMemo(
    () => (decodedToken ? { id: decodedToken.brain_session_id } : undefined),
    [decodedToken],
  );
  // This is an object for historical reasons, it should be refactored later
  const authData: AuthDataState = useMemo(() => ({ isAuthInit }), [isAuthInit]);

  const isAnonymous = decodedToken?.is_anonymous ?? true;

  const removeStorage = () => {
    localStorage.removeItem(DOMAIN.registerSourceSession);
    sessionStorage.removeItem(DOMAIN.myScooprUser);
    removeTokens();
    queryClient.clear();
  };

  const logout = async () => {
    removeStorage();
    Sentry.setUser(null);
    Sentry.setTag('user.analytics_user_id', undefined);
    try {
      await AuthHttp.post.accountLogout(
        tokens?.accessToken,
        tokens?.refreshToken,
      );
    } catch (ex) {
      const errorContent = transformers.prepareResponseError(ex!);
      console.log('ex', errorContent);
    }
    addLegacyGTMEvent('userLogout');
    window.location.href = `${API.url}/account/logout/?redirect_url=${API.domain}${LANG.routes.LOGGED_OUT}`;
  };

  /** @deprecated can be extracted after tracking module refactoring is done */
  const getVertex = (clientKnowledge: unknown) => {
    const rParams = getRegisterSourceData();
    const getCookie = (name: string) => {
      return Cookies.get(name) || '';
    };

    return {
      client_knowledge: clientKnowledge,
      user_agent: window.navigator.userAgent,
      ga_client_id: getGaClientId(),
      rpcid: rParams.params.rpcid || null,
      ga_session_id: getCookie(GTM.sessionIdCookie),
      cidb: getCookie('_ga'),
      gaexp: getCookie('_gaexp'),
      gcl_au: getCookie('_gcl_au'),
      gcl_aw: getCookie('_gcl_aw'),
      gcl_dc: getCookie('_gcl_dc'),
      fbp: getCookie('_fbp'),
      fbc: getCookie('_fbc'),
      SCOOPR_OPTIMIZE_EXP: getCookie('SCOOPR_OPTIMIZE_EXP'),
      ttclid: getCookie('ttclid'),
      _ttp: getCookie('_ttp'),
      snapchat_uuid_c1: getCookie('uuid_c1'),
      snapchat_sc_cid: rParams.params.ScCid || null,
      page_url: window.location.href,
    };
  };

  /** @deprecated to be removed */
  const acceptConsents = async (consents: string[]) => {
    if (tokens) {
      try {
        await applyConsents(consents);
        await applyConsents.invalidateCache(queryClient);
      } catch (ex) {
        const errorContent = transformers.prepareResponseError(ex!);
        console.log('ex', errorContent);
      }
    }
  };

  /** @deprecated to be extracted */
  const deleteUser = async () => {
    try {
      await AuthHttp.delete.accountDelete();
      addLegacyGTMEvent('userDeleted', { user_id: userId });
      await logout();
    } catch (ex) {
      const errorContent = transformers.prepareResponseError(ex!);
      console.log('ex', errorContent);
    }
  };

  const login = async (method: string, returnTo?: string | null) => {
    try {
      const res = await apiClient
        .get('account/login/', {
          searchParams: {
            authentication_provider: method === 'vipps' ? 'VIPPS' : 'BANK_ID',
            ...(returnTo && { return_to: returnTo }),
          },
        })
        .json<{ url?: string }>();

      if (res?.url) {
        window.location.href = res.url;
      }
    } catch (e) {
      await logout();
      console.error(e);
    }
  };

  const value: AuthContextValue = {
    acceptConsents,
    login,
    logout,
    deleteUser,
    getVertex,
    token: tokens,
    authData,
    userId,
    brainSessionId,
    isAnonymous,
    isAuthInit,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
