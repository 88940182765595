import { API } from '../../Config';
import Http from '../Http';

function apiDeleteAccountDelete() {
  return Http.delete(`${API.url}/account/delete/`);
}

/**
 * @typedef {object} PatchAccountMeArgs
 * @property {string} [email]
 * @property {string} [phoneNumber]
 */

function apiPatchAccountMe(
  /** @type {PatchAccountMeArgs} */ { email, phoneNumber },
) {
  return Http.patch(`${API.url}/account/me/`, {
    email,
    phone_number: phoneNumber,
  });
}

function apiPostAccountLogout(access, refresh) {
  return Http.post(`${API.url}/account/logout/`, {
    access,
    refresh,
  });
}

/** @deprecated use functions from `methods.ts` or `queries.ts` */
export const AuthHttp = {
  post: {
    accountLogout: apiPostAccountLogout,
  },
  patch: {
    accountMe: apiPatchAccountMe,
  },
  delete: {
    accountDelete: apiDeleteAccountDelete,
  },
};
