import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import {
  Outlet,
  redirect,
  useLocation,
  useNavigate,
  type RouteObject,
} from 'react-router-dom';

import { isMyScooprDataPending } from './_store';
import { myScooprConsents } from './config';
import { getMyScooprRedirect } from './redirects';

import routes from '@/assets/constants/routes-no';
import Loader from '@/components/Loader';
import { useMe } from '@/hooks/useMe';
import { addLegacyGTMEvent } from '@/lib/analytics/gtm';
import {
  authInitQuery,
  checkManyConsentsQuery,
  getConsentsQuery,
  meQuery,
} from '@/lib/api/queries';
import {
  getReturnUrl,
  requireAuth,
  simpleRace,
  smartLazy,
} from '@/lib/router-utils';
import { withSearchParams } from '@/lib/utils';
import { queryClient } from '@/queryClient';
import { getIsAnonymous } from '@/services/tokens';

// eslint-disable-next-line react-refresh/only-export-components
const Protected = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { data: me } = useMe();
  const { data: consents } = useQuery(checkManyConsentsQuery(myScooprConsents));

  const isReady = me && consents;

  const redirect =
    isReady && getMyScooprRedirect({ me, consents, currentLocation: location });

  useEffect(() => {
    if (redirect) {
      navigate(redirect);
    }
  });

  return redirect || !isReady ? <Loader /> : <Outlet />;
};

const requireMyScooprData = async (request: Request) => {
  if (isMyScooprDataPending()) {
    const redirectUrl = withSearchParams(routes.MY_SCOOPR_DASHBOARD, {
      return_to: getReturnUrl(request.url),
    });

    throw redirect(redirectUrl);
  }
};

export const myScooprRoutes = [
  {
    path: routes.MY_SCOOPR_LOGIN,
    loader: async () => {
      const isAnonymous = await getIsAnonymous();

      if (!isAnonymous) {
        return redirect(routes.MY_SCOOPR_DASHBOARD);
      }

      return null;
    },
    lazy: () => smartLazy(import('./pages/Login')),
  },
  {
    path: routes.MY_SCOOPR,
    loader: async ({ request }) => {
      await requireAuth(request);
      queryClient.prefetchQuery(meQuery());
      queryClient.prefetchQuery(checkManyConsentsQuery(myScooprConsents));
      return null;
    },
    shouldRevalidate: () => true,
    element: <Protected />,
    children: [
      {
        path: routes.MY_SCOOPR,
        loader: () => redirect(routes.MY_SCOOPR_DASHBOARD),
      },
      {
        path: routes.MY_SCOOPR_LOGIN_LOADING,
        loader: async () => {
          const data = await queryClient.fetchQuery(authInitQuery());
          addLegacyGTMEvent('userLoginCompleted', { user_id: data.userId });
          return redirect(routes.MY_SCOOPR_DASHBOARD);
        },
      },
      {
        path: routes.MY_SCOOPR_CONSENTS,
        loader: async ({ request }) => {
          await requireAuth(request);
          return null;
        },
        lazy: () => smartLazy(import('./pages/Consents')),
      },
      {
        path: routes.MY_SCOOPR_EMAIL,
        loader: async ({ request }) => {
          await requireAuth(request);
          return null;
        },
        lazy: () => smartLazy(import('./pages/Email')),
      },
      {
        path: routes.MY_SCOOPR_PHONE,
        loader: async ({ request }) => {
          await requireAuth(request);
          return null;
        },
        lazy: () => smartLazy(import('./pages/Phone')),
      },
      {
        path: routes.MY_SCOOPR_DASHBOARD,
        loader: async ({ request }) => {
          await requireAuth(request);
          return null;
        },
        lazy: () => smartLazy(import('./pages/Dashboard')),
      },
      {
        path: routes.MY_SCOOPR_MY_CONSENTS,
        loader: async ({ request }) => {
          await requireAuth(request);
          await requireMyScooprData(request);
          return simpleRace([queryClient.prefetchQuery(getConsentsQuery())]);
        },
        lazy: () => smartLazy(import('./pages/MyConsents')),
      },
      {
        path: routes.MY_SCOOPR_MY_DEBT,
        loader: async ({ request }) => {
          await requireAuth(request);
          await requireMyScooprData(request);
          return null;
        },
        lazy: () => smartLazy(import('./pages/MyDebt')),
      },
      {
        path: routes.MY_SCOOPR_MY_CREDIT_SCORE,
        loader: async ({ request }) => {
          await requireAuth(request);
          await requireMyScooprData(request);
          return null;
        },
        lazy: () => smartLazy(import('./pages/MyCreditScore')),
      },
      {
        path: routes.MY_SCOOPR_SAVINGS_POTENTIAL,
        loader: async ({ request }) => {
          await requireAuth(request);
          await requireMyScooprData(request);
          return null;
        },
        lazy: () => smartLazy(import('./pages/SavingsPotential')),
      },
    ],
  },
] satisfies RouteObject[];
